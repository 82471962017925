import Controller from './controller'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const logDecorator = require('../../../../lib/decorators/log')
  logDecorator(Controller)
}

export default new Controller({
  selector: '.m-video-wafer'
})
