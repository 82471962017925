const CLASSNAME_CONTAINER = 'm-video-wafer'
const SELECTOR_DESC_TITLE = `.${CLASSNAME_CONTAINER}__description--title`
const SELECTOR_DESC_DESCRIPTION = `.${CLASSNAME_CONTAINER}__description--text`
const SELECTOR_DESC_FB_SHARE = `.${CLASSNAME_CONTAINER}__share--facebook`
const SELECTOR_DESC_TW_SHARE = `.${CLASSNAME_CONTAINER}__share--twitter`
const SELECTOR_DESC_E_SHARE = `.${CLASSNAME_CONTAINER}__share--email`
const SHARE_LINK_FB = 'https://www.facebook.com/sharer.php'
const SHARE_LINK_TW = 'https://www.twitter.com/share'
const SHARE_LINK_E = 'mailto:'
const URL_VIDEO = '/video/play'

export default (() => {
  class VideoPlayer {
    constructor({ elem, player }) {
      if (!elem || !player) {
        return
      }

      this.elem = elem
      this.player = player
      this.containerElem = wafer.utils.findAncestor(this.elem, CLASSNAME_CONTAINER)
      this.descriptionFields = {
        title: this.containerElem.querySelector(SELECTOR_DESC_TITLE),
        description: this.containerElem.querySelector(SELECTOR_DESC_DESCRIPTION),
        fbShare: this.containerElem.querySelector(SELECTOR_DESC_FB_SHARE),
        twShare: this.containerElem.querySelector(SELECTOR_DESC_TW_SHARE),
        eShare: this.containerElem.querySelector(SELECTOR_DESC_E_SHARE)
      }
      this.hasDescription = this._checkForDescriptionFields()
    }

    updateDescription() {
      if (!this.hasDescription) {
        return
      }
      const videoTitle = this.player.playlist.getCurrentItemTitle()
      const encodedVideoTitle = encodeURIComponent(videoTitle)
      const videoDescription = this.player.playlist.getCurrentItemDescription()

      const playlistId = this.player.config.playlist.id
      const encodedVidUrl = encodeURIComponent(
        `${window.location.origin}${URL_VIDEO}/${playlistId}/${videoTitle}`
      )

      const { title, description, fbShare, twShare, eShare } = this.descriptionFields
      if (title) {
        title.textContent = videoTitle
      }
      if (description) {
        description.textContent = videoDescription
      }
      if (fbShare) {
        fbShare.href = `${SHARE_LINK_FB}?t=${encodedVideoTitle}&u=${encodedVidUrl}`
      }
      if (twShare) {
        twShare.href = `${SHARE_LINK_TW}?url=${encodedVidUrl}&text=${encodedVideoTitle}&via=AOL`
      }
      if (eShare) {
        eShare.href = `${SHARE_LINK_E}?subject=Check this out: ${encodedVideoTitle}&body=${encodedVidUrl}`
      }
    }

    _checkForDescriptionFields() {
      return Object.values(this.descriptionFields).some(elem => !!elem)
    }
  }

  return VideoPlayer
})()
