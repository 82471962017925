import VideoPlayer from './video_player'

const PLAYER_EVENTS = {
  PLAYER_READY: 'video:ready',
  PLAYBACK_START: 'video:playbackstart'
}

export default (() => {
  class VideoPlayerController {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }
      this.videoPlayers = new Map()

      window.wafer.ready(() => {
        this.init()
      })
    }

    init() {
      window.wafer.on(PLAYER_EVENTS.PLAYER_READY, data => {
        this._updateDescription(data)
      })
      window.wafer.on(PLAYER_EVENTS.PLAYBACK_START, data => {
        this._updateDescription(data)
      })
    }

    _updateDescription(data) {
      const videoPlayer = this._getPlayer(data)
      videoPlayer.updateDescription()
    }

    _getPlayer(data) {
      const { elem, meta: { instance } = {} } = data
      if (this.videoPlayers.has(elem)) {
        return this.videoPlayers.get(elem)
      }
      const videoPlayer = new VideoPlayer({ elem, player: instance })
      this.videoPlayers.set(elem, videoPlayer)
      return videoPlayer
    }
  }

  return VideoPlayerController
})()
